.container {
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-weight: 600;
    font-size: 24px;
    color: var(--lightTextColor);
    cursor: pointer;

    &.nextBtn {
      color: var(--primaryColor);
    }

    span {
      margin-top: -2px
    }
  }

  .ml10 {
    margin-left: 10px;
  }

  .mr10 {
    margin-right: 10px;
  }

  @media screen and (max-width: 1024px) {
    .button {
      font-size: 16px;
    }
  }
}