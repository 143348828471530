.container {
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  padding-bottom: 100px;

  .title {
    color: var(--textColor);
    font-weight: 500;
    font-size: 36px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .slider {
    display: grid;
    margin: 30px 100px 10px 100px;
    padding-bottom: 30px;
  }

  .thumb {
    width: 42px;
    height: 42px;
    border-radius: 21px;
    background-color: var(--white);
    margin-top: -14px;
    box-shadow: 4px -1px 10px -2px gray;
    outline: none !important;
  }

  .track {
    background-color: #E7E5E9;
    height: 16px;
    border-radius: 8px;
  }

  .progress {
    background-color: var(--primaryColor);
  }

  .minutes {
    font-weight: 500;
    font-size: 24px;
    color: var(--lightTextColor);
    margin-bottom: 50px;
  }

  @media screen and (max-width: 1024px) {
    .icon {
      width: 40px;
      height: 40px;
    }

    .title {
      font-size: 24px;
    }

    .slider {
      margin: 30px 10px 10px 10px
    }

    .thumb {
      width: 36px;
      height: 36px;
      margin-top: -10px;
    }

    .minutes {
      font-size: 16px;
    }
  }
}