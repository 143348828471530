.container {
  width: 1020px;
  max-width: calc(100% - 32px);
  margin: auto;
  align-items: center;
  position: relative;
  z-index: 10;
  margin-top: 100px;

  .title {
    font-weight: 500;
    font-size: 56px;
    color: var(--white);
    text-align: center;
    margin-bottom: 50px;
  }

  .result {
    width: 100%;
    background-color: var(--white);
    border-radius: 56px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .footerTitle {
    font-weight: 500;
    font-size: 56px;
    color: var(--white);
    text-align: center;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 60px;
    max-width: 85%;
  }

  .footerDesc {
    font-weight: 400;
    font-size: 36px;
    color: var(--white);
    text-align: center;
  }

  .workoutBtn {
    margin: auto;
    margin-top: 60px;
    width: 200px;
    text-align: center;
    // margin: 30px 100px 20px 100px;
    background: var(--imageGradient);
    padding: 10px 0;
    color: var(--white);
    font-weight: 400;
    font-size: 24px;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 70px;
    
    .title {
      font-size: 32px;
    }

    .footerTitle {
      font-weight: 400;
      font-size: 32px;
      max-width: 100%;
    }

    .footerDesc {
      font-weight: 400;
      font-size: 14px;
      margin-top: 30px;
    }

    .workoutBtn {
      width: 150px;
      font-size: 16px;
    }
  }
}