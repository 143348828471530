.container {
  max-width: 100%;
  margin: 30px 100px 39px 100px;
  background-color: var(--white);
  border-radius: 56px;
  width: 1020px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;

  .title {
    color: #333333;
    font-weight: 500;
    font-size: 36px;
    max-width: 60%;
    text-align: center;
    margin-top: 30px;
  }

  @media screen and (max-width: 1024px) {
    .title {
      font-size: 24px;
      max-width: 90%;
    }
    .icon {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: calc(100% - 32px);
    padding: 30px 0;
  }
}