.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .beta {
    font-weight: 400;
    font-size: 24px;
    color: var(--white);
  }

  .title {
    font-weight: 500;
    font-size: 56px;
    color: var(--white);
    margin-top: 20px;
    max-width: 650px;
    text-align: center;
  }

  .swiper {
    max-width: 100%;
    background-color: var(--white);
    border-radius: 56px;
    width: 1020px;
    margin-top: 50px;
    position: relative;
    overflow: hidden;
  }

  @media screen and (max-width: 1024px) {
    .beta {
      font-size: 16px;
    }

    .title {
      font-size: 32px;
      max-width: 80%;
      margin-top: 10px;
    }

    .swiper {
      max-width: calc(100% - 32px);
    }
  }
}