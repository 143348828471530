.container {
  position: relative;
  width: 100%;
  margin-top: -45px;

  .wave {
    width: 100%;
    height: 100%;
  }

  .div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    top: 50px;
    height: 80%;
  }

  .img1 {
    height: 100%;
  }

  .img2 {
    height: 100%;
  }

  .arrowWrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1024px) {
    .div {
      height: 35%;
      top: 38%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    height: 500px;
  }
}