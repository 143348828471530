.button {
  color: var(--lightTextColor);
  font-weight: 700;
  font-size: 12px;
  padding: 10px 0;
  background-color: #F8F6F8;
  border-radius: 12px;
  border: solid 1px #A5A3A6;
  cursor: pointer;

  &.selected {
    background-color: var(--white);
    border: solid 1px #FF5818;
    color: #FF5818;
  }
}