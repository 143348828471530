.container {
  border: solid 1px var(--white);
  font-weight: 400;
  font-size: 24px;
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.25);
  padding: 10px 60px;
  cursor: pointer;;
}

@media screen and (max-width: 1024px) {
  .container {
    font-size: 16px;
  }
}