.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 200px 0;
  position: relative;

  .title {
    font-size: 72px;
    font-weight: 600;
    text-align: center;
    color: var(--white);
  }
  
  .description {
    font-size: 28px;
    font-weight: 400;
    color: var(--white);
    max-width: 845px;
    text-align: center;
  }

  .button {
    margin-top: 50px;
    z-index: 1;
  }

  .downArrow {
    margin-top: 200px;
  }

  @media screen and (max-width: 1024px) {
    .title {
      font-size: 42px;
      max-width: 80%;
      line-height: 42px;
      margin-bottom: 18px;
    }

    .description {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    padding: 50px 0;
  }
}