.container {
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  padding-bottom: 100px;

  .title {
    color: var(--textColor);
    font-weight: 500;
    font-size: 36px;
  }

  .emailField {
    display: flex;
    align-items: center;
    margin: 30px 100px 39px 100px;
    padding: 10px;
    border: solid 1px #A5A3A6;
    border-radius: 8px;

    input {
      flex-grow: 1;
      border: none;
      outline: none;
      margin-left: 10px;
      font-size: 16px;
      color: var(--textColor);
    }
  }

  .workoutBtn {
    margin: 30px 100px 20px 100px;
    background: var(--imageGradient);
    padding: 10px 0;
    color: var(--white);
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) {
    .icon {
      width: 40px;
      height: 40px;
    }

    .title {
      font-size: 24px;
    }

    .workoutBtn {
      font-size: 12px;
      margin: 30px 10px 20px 10px;
    }

    .emailField {
      margin: 30px 10px 30px 10px
    }
  }
}