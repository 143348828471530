.container {
  width: 100%;
  height: 600px;
  margin-bottom: -180px;
  margin-top: -150px;
  overflow: hidden;
  

  .wave {
    width: 100%;
    height: 900px;
  }
  
}

@media screen and (max-width: 1024px) {
  .container {
    height: 300px;

    .wave {
      height: 500px;
    }
  }
}