.container {
  display: flex;
  width: 1020px;
  margin: auto;
  margin-top: 200px;
  max-width: 100%;

  .info {
    justify-content: center;
    display: flex;
    flex-direction: column;
    flex: 1;

    .title {
      font-weight: 500;
      font-size: 56px;
      color: var(--white);
    }
  
    .description {
      font-weight: 500;
      font-size: 24px;
      color: var(--white);
      margin-top: 30px;
    }

    @media screen and (max-width: 1024px) {
      .title {
        font-size: 32px;
      }

      .description {
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }
 
  .imageWrap {
    background: var(--imageGradient);
    border-radius: 56px;
    margin-right: 100px;
    flex: 1;

    img {
      margin-top: -100px;
      width: 100%;
    }
  }


  @media screen and (max-width: 1024px) {
    .imageWrap {
      margin-right: 0;
      flex: none;
      order: 2;
      margin-top: 100px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .info {
      flex: none;
      order: 1;
      padding: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .container {
    flex-direction: column;
    margin-top: 100px;
  }
}
