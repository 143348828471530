.container {
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  @media screen and (max-width: 1024px) {
    flex-direction: column;  
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .info {
    width: 50%;
    padding-left: 40px;
    padding-right: 10px;
    display: flex;
    @media screen and (max-width: 1024px) {
      width: calc(100% - 20px);
      padding-left: 20px;
    }

    span {
      font-weight: 800;
      font-size: 36px;
      color: var(--lightTextColor);
      margin-right: 15px;

      @media screen and (max-width: 1024px) {
        font-size: 24px;
      }
    }

    .title {
      font-weight: 500;
      font-size: 32px;
      margin-top: 1px;
      @media screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }

    .workoutInfo {
      font-weight: 600;
      font-size: 24px;
      color: var(--lightTextColor);
      margin-top: 10px;
      @media screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }

  >div:nth-child(2) {
    width: 50%;
    padding-right: 40px;
    padding-left: 10px;
    position: relative;

    .playBtn {
      position: absolute;
      left: calc(50% - 32px);
      top: calc(50% - 32px);
      cursor: pointer;
    }

    video {
      width: 100%;
      aspect-ratio: 1.5;
      border-radius: 20px;
      object-fit: cover;
      object-position: center;
    }

    @media screen and (max-width: 1024px) {
      width: calc(100% - 65px);
      margin-left: 30px;
      margin-top: 20px;
      margin-right: 30px;

      .playBtn {
        width: 34px;
        height: 34px;
        left: calc(50% - 34px);
        top: calc(50% - 17px);
      }
    }
  }

  .divider {
    background-color: var(--lightTextColor);
    position: absolute;
    left: 48px;
    top: 100px;
    bottom: 20px;
    width: 1px;

    @media screen and (max-width: 1024px) {
      left: 26px;
      top: 60px;
    }
  }
}