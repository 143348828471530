:root {
  --bgColor: #171717;
  --primaryGradient: linear-gradient(rgba(255, 146, 0, 0.2325) 0%, rgba(44, 44, 44, 0.25) 100% );
  --imageGradient: linear-gradient(#ff5818 0%, rgba(255, 146, 0, 0.93) 100% );
  --white: white;
  --textColor: #333333;
  --lightTextColor: #A5A3A6;
  --primaryColor: #FF5818;
  --swiper-pagination-color: var(--primaryColor);
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bottom: 45px;
}

@media screen and (max-width: 1024px) {
  :root {
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
  }
}


html {
  background: var(--bgColor);
}

body {
  background: var(--primaryGradient);
  font-family: "Figtree", sans-serif;
}