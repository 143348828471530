.container {
  max-width: 100%;
  margin: auto;
  margin-top: 30px;
  text-align: center;
  padding-bottom: 100px;

  .title {
    color: var(--textColor);
    font-weight: 500;
    font-size: 36px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 5px;
    row-gap: 5px;
    margin: 30px 100px 10px 100px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 1024px) {
    .icon {
      width: 40px;
      height: 40px;
    }

    .title {
      font-size: 24px;
    }
    
    .list {
      grid-template-columns: 1fr 1fr 1fr;
      margin: 30px 10px 10px 10px;
    }
  }
}

